var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isLoadingData)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(_vm.inspection && _vm.register)?_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","align":"center"}},[_c('v-col',[_c('h1',{staticClass:"text-h6 primary--text",domProps:{"textContent":_vm._s(_vm.inspection && _vm.inspection.code)}}),_c('h2',{staticClass:"text-body-2 mb-0",domProps:{"textContent":_vm._s(_vm.inspection && _vm.inspection.name)}})])],1),(_vm.register)?_c('responsible-inpection',{attrs:{"register":_vm.register}}):_vm._e(),(_vm.inspection)?_c('v-card',{staticClass:"my-8",attrs:{"elevation":"2"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"ma-6",attrs:{"elevation":0}},[_c('v-card-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v("INICIO")]),_c('v-card-text',[_c('form-header',{attrs:{"headers":_vm.inspection.headers,"disabled":_vm.register && _vm.register.status != 'DRAFT'}})],1)],1),_c('hr'),_vm._l((_vm.inspection.bodies),function(group,i){return [_c('v-card',{key:i + 'card',staticClass:"ma-6",attrs:{"elevation":0}},[_c('v-card-title',{staticClass:"subtitle-1 font-weight-medium"},[_vm._v(_vm._s(group.group))]),_c('v-card-text',[_vm._l((group.items),function(item,index){return [_c('v-row',{key:index,attrs:{"align":"center","justify":"space-between","dense":""}},[_c('v-col',{class:{ 'mt-5': index > 0 },attrs:{"cols":"8","sm":"8"}},[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(item.description))])]),_c('v-col',{attrs:{"cols":"auto","sm":"auto"}},[_c('v-radio-group',{staticClass:"ml-0 ml-md-5 mt-2 p-0 mt-md-0 mb-1 mb-md-0",attrs:{"value":item.value,"row":"","hide-details":"","disabled":_vm.isDisabled()},on:{"change":function (value) {
														_vm.setValueRadio(
															value,
															i,
															index
														);
													}}},_vm._l((group.options_answer),function(option,iO){return _c('v-radio',{key:'radio-' + iO,attrs:{"label":option.label,"value":option.value,"color":option.color}})}),1)],1),(item.value == 'NO')?_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":"Detalle la(s) observacion(es)","rows":"3","hide-details":"","outlined":"","disabled":_vm.register.status != 'DRAFT'},model:{value:(item.observations),callback:function ($$v) {_vm.$set(item, "observations", $$v)},expression:"item.observations"}})],1):_vm._e()],1),(index < group.items.length - 1)?_c('v-divider',{key:index + 'hr',staticClass:"hidden-sm-and-down mt-5"}):_vm._e()]}),_c('br'),_c('iframe',{staticStyle:{"display":"none"},attrs:{"id":"iframeprint"}})],2)],1),_c('hr',{key:i + 'hr'})]})],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center flex-wrap",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-body-2 font-weight-bold mr-5"},[_vm._v(" Valor Total: ")]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('v-alert',{staticClass:"pa-2 mr-2",attrs:{"text":"","outlined":"","color":"info"},domProps:{"textContent":_vm._s(
												'Criterios Aplicados: ' +
												_vm.totalItemsApply
											)}}),_vm._l((_vm.optionsAnswer),function(optionA,indexOA){return _c('v-alert',{key:'op-' + indexOA,staticClass:"pa-2 mr-2",attrs:{"text":"","outlined":"","color":optionA.color},domProps:{"textContent":_vm._s(
												_vm.getLabel(optionA.value) +
												': ' +
												optionA.total
											)}})})],2)]),_c('v-col',{staticClass:"d-flex align-center flex-wrap",attrs:{"cols":"12"}},[_c('p',{staticClass:"text-body-2 font-weight-bold mr-5"},[_vm._v(" Porcentaje de cumplimiento: ")]),_c('v-alert',{staticClass:"pa-2",attrs:{"text":"","outlined":"","color":"info"},domProps:{"textContent":_vm._s(
											parseFloat(
												parseFloat(
													(
														(100 * _vm.register.items_yes) /
														(_vm.register.items_yes +
															_vm.register.items_no)
													).toString()
												).toFixed(0)
											) + '%'
										)}})],1),(_vm.isCompletedLoad)?_c('v-btn',{staticClass:"ma-2 d-none",attrs:{"color":"primary"},on:{"click":function($event){return _vm.window.print()}}}):_vm._e()],1)],1)],1)],1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }